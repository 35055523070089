export default {
  async mounted() {
    if (import.meta.client) {
      await this.$nextTick(() => this.$lightbox?.refresh())
    }
  },
  watch: {
    loading(value) {
      !value && this.$nextTick(() => this.$lightbox?.refresh())
    },
  },
}
